<template>
  <div class="Valsartan">
    <div
        class="game-wrapper"
        :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
      ]"
    >

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text  green">
            Добрий день! Лікар виписав нам рецепт на <span>засіб від тиску</span>. Необхідно, щоб це був відомий бренд!
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-valsartan/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text  blue">
            <span>Вітаю! Погляньмо на рецепт, що саме Вам призначив лікар</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-valsartan/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            До рецепта
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block popup stage3">
          <img src="../../assets/img/game-valsartan/stage-3-paper.png" alt="">

          <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
            <p>Рекомендувати</p>
          </div>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text  blue">
            <span>Вже дивлюсь, що я можу Вам порадити!</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-valsartan/stage-4-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup stage5">

          <div class="game-wrapper__stage--top-text">

            <svg class="desk" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M83.5359 6C85.0755 3.33333 88.9245 3.33333 90.4641 6L158.88 124.5C160.42 127.167 158.495 130.5 155.416 130.5H18.584C15.5048 130.5 13.5803 127.167 15.1199 124.5L83.5359 6Z"
                  stroke="white" stroke-width="4"/>
              <path
                  d="M92.1481 90.5571H81.8519L80 55H94L92.1481 90.5571ZM80 103.343C80 100.871 80.6667 99.1564 82 98.1981C83.3827 97.1894 85.037 96.685 86.963 96.685C88.8395 96.685 90.4444 97.1894 91.7778 98.1981C93.1111 99.1564 93.7778 100.871 93.7778 103.343C93.7778 105.663 93.1111 107.352 91.7778 108.411C90.4444 109.47 88.8395 110 86.963 110C85.037 110 83.3827 109.47 82 108.411C80.6667 107.352 80 105.663 80 103.343Z"
                  fill="white"/>
            </svg>

            <svg class="mob" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M45.5359 6C47.0755 3.33333 50.9245 3.33333 52.4641 6L87.9711 67.5C89.5107 70.1667 87.5862 73.5 84.507 73.5H13.4929C10.4137 73.5 8.48926 70.1667 10.0289 67.5L45.5359 6Z"
                  stroke="white" stroke-width="4"/>
              <path
                  d="M51.9418 51.0413H46.0582L45 31H53L51.9418 51.0413ZM45 58.2476C45 56.8547 45.381 55.8881 46.1429 55.348C46.933 54.7795 47.8783 54.4952 48.9788 54.4952C50.0511 54.4952 50.9683 54.7795 51.7302 55.348C52.4921 55.8881 52.873 56.8547 52.873 58.2476C52.873 59.5553 52.4921 60.5076 51.7302 61.1045C50.9683 61.7015 50.0511 62 48.9788 62C47.8783 62 46.933 61.7015 46.1429 61.1045C45.381 60.5076 45 59.5553 45 58.2476Z"
                  fill="white"/>
            </svg>


            Обережно,<br>
            зона підвищеного тиску!

          </div>

          <div class="game-wrapper__stage--bottom-text">
            Для того щоб порадити відвідувачу лікарський засіб, спробуйте пройти квест та нормалізувати тиск.
            Але попереджаємо, знадобиться неабияка уважність.

            <span>Впораєтесь?</span>
          </div>

          <div class="game-wrapper__stage--btn-next-stage small-m hover_type6" @click="nextStage(6)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <ValsartanGame v-if="stage === 6"></ValsartanGame>
    </div>

    <div
        class="notes-wrapper"
        v-show="stage !== 6"
        :class="{ active: stage === 3 || stage === 5 }"
    >
      <span
      >Інформація про лікарський засіб. РП №UA/5463/01/02, №UA/5463/01/03. Для професійної діяльності медичних та фармацевтичних працівників.

      </span>

      <span>
        Валсартан-Тева (1 таблетка містить валсартану 80 мг або 160 мг). Характеристики: таблетки 80 мг: рожеві, круглі, двоопуклі таблетки, вкриті плівковою оболонкою, з лінією розлому з двох сторін, логотипом «V» з однієї сторони і лініями розлому по боках; таблетки 160 мг: жовті, овальні, двоопуклі таблетки, вкриті плівковою оболонкою, з логотипом «V» з однієї сторони і лінією розлому з іншої сторони та по боках. Лікувальні властивості: антагоністом рецепторів ангіотензину ІІ. Використовується для лікування артеріальної гіпертензії та постінфарктному періоді. Найбільш частими побічними реакціями є: вірусні інфекції, запаморочення, постуральне запаморочення, ортостатична гіпотензія, підвищення рівня креатиніну сироватки крові, підвищення рівня сечовини крові.
      </span>

      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284
      </span>
      <span>CBG-UA-00828. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <img
        v-if="
        stage === 1 ||
        stage === 2 ||
        stage === 4 ||
        stage === 6
      "
        class="bg"
        src="../../assets/img/game-valsartan/bg.png"
        alt=""
    />

    <img
        v-else
        class="bg"
        src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
        alt=""
    />
  </div>
</template>

<script>
import ValsartanGame from "../../components/Valsartan-game";

export default {
  name: "Valsartan",

  components: {
    ValsartanGame,
  },

  data: () => ({
    stage: 1,
  }),

  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.Valsartan {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage7 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;


        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;
        height: 110px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: 0;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          height: auto;
          padding: 0;
          padding-bottom: 30px;

          img {
            max-width: 290px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
            padding-top: 25px;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        &.big {
          font-size: 32px;
          line-height: 120%;

        }

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: 45px;

        @media screen and (max-width: 425px) {
          max-width: 274px;
        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 40px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        padding: 0 20px;

        span {
          font-family: $EB;
          display: block;
          margin-top: 12px;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 37px 30px 29px 30px;
        text-align: center;

        font-family: $EB;
        font-size: 32px;
        line-height: 120%;
        color: white;

        width: 100%;

        svg {
          max-width: 174px;
          width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
          margin-bottom: -25px;

          &.mob {
            display: none;
          }
        }

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      &--bottom-text {
        color: #424242;
        font-size: 20px;
        line-height: 120%;

        font-family: $M;

        padding: 23px 30px 0 30px;
        text-align: center;

        span {
          display: block;
          margin-top: 30px;
          font-family: $B;
        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Valsartan {

    padding-top: 0;

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &__stage {

        &.stage6 {
          padding: 0;
        }

        &--white-block {
          height: 90px;
          padding: 10px;

          &.bottom {
            height: 100px;
          }

          &.stage5 {
            height: auto;
          }

          &.custom-p {
          }

          &.popup {
            height: auto;
            padding-bottom: 17px;

            img {
              padding-top: 16px;
              max-width: 221px;
            }
          }
        }

        &--text {
          font-size: 18px;
          line-height: 18px !important;

          &.big {
            font-size: 18px;
            line-height: 20px;
          }

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {
          margin-top: 55px;

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-m {
            margin-top: 20px;
          }

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--popup-text {

          span {
            margin-top: 6px;
          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 24px;
          line-height: 120%;
          padding: 15px 20px;

          svg {
            max-width: 98px;
            margin-bottom: -15px;

            &.mob {
              display: block;
            }

            &.desk {
              display: none;
            }
          }
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 16px 20px 0 20px;

          span {
            margin-top: 20px;
          }
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}
</style>
